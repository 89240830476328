<template>
  <div>
    <crud-table
      v-if="brandList.length"
      ref="crudTable"
      :show-brands-filter="true"
      :list-items="listItems"
      :brand-list="brandList"
      :is-editable="false"
      heading="Posts"
      row-click-path="/tables/posts"
      endpoint="posts"
      query-string="&language=ALL"
      @create="createPost()"
      @edit="data => editPost(data)"
      @delete="id => deletePost(id)"
      @restore="id => restorePost(id)"
    />

    <edit-dialog
      :show.sync="editDialog.display"
      :max-width="'600'"
      @dialogConfirm="editDialog.saveFunction">
      <template>
        <v-container grid-list-xl>
          <v-layout
            row
            wrap>
            <v-flex
              xs12
              sm6>
              <v-select
                :items="typeList"
                v-model="editDialog.data.type"
                :rules="[v => Boolean(v) || 'Please select a type']"
                label="Type"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-select
                :items="languageList"
                v-model="editDialog.data.language"
                :rules="[v => Boolean(v) || 'Please select a language']"
                label="Language"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-text-field
                v-model="editDialog.data.title"
                :max-length="TEXT_FIELD_MAX_LENGTH"
                :rules="[v => Boolean(v) || 'Please provide a title']"
                label="Title"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <InputHotKeyWrapper>
                <v-text-field
                  v-model="editDialog.data.weight"
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  :rules="[v => Boolean(v) || 'Please provide a weight']"
                  type="number"
                  label="Weight"
                />
              </InputHotKeyWrapper>
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-select
                v-if="brandList.length"
                :items="brandList"
                :rules="[v => v.length>0 || 'Please select at least 1 brand']"
                v-model="editDialog.data.brands"
                :item-text="(val) => { return val.name }"
                clearable
                item-value="id"
                multiple
                label="Brands"
              />
            </v-flex>
            <v-flex
              xs12>
              <v-textarea
                v-model="editDialog.data.body"
                label="Body"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </edit-dialog>
  </div>
</template>

<script>
import CrudTable from '../../../modules/admin/CrudTable.vue'
import Request from '../../../helpers/request'
import Brands from '../../../helpers/brands'
import { TEXT_FIELD_MAX_LENGTH, POST_TYPES, LANGUAGE_TYPES } from '../../../constants/common'

export default {
  components: { CrudTable },
  mixins: [Request, Brands],
  data () {
    return {
      brandList: [],
      typeList: POST_TYPES,
      languageList: LANGUAGE_TYPES,
      TEXT_FIELD_MAX_LENGTH,
      listItems: [
        { text: 'ID', value: 'id' },
        { text: 'Brands', value: 'brands', objectKey: 'name' },
        { text: 'Title', value: 'title' },
        { text: 'Weight', value: 'weight' },
        { text: 'Type', value: 'type' },
        { text: 'Language', value: 'language' },
        { text: 'Body', value: 'body' },
        { text: '', value: null }
      ],
      loading: true,
      data: [],
      editDialog: {
        display: false,
        saveFunction: () => {},
        data: {}
      }
    }
  },
  async beforeMount () {
    try {
      this.brandList = await this.fetchBrands()
    } catch (e) {
      throw new Error(e)
    }
  },
  methods: {
    createPost () {
      this.editDialog.display = true
      this.editDialog.data = { }

      this.editDialog.type = 'create'

      this.editDialog.saveFunction = () => {
        let body = {
          data: {
            title: this.editDialog.data.title,
            weight: this.editDialog.data.weight,
            type: this.editDialog.data.type,
            language: this.editDialog.data.language,
            body: this.editDialog.data.body,
            brands: this.editDialog.data.brands.map(brand => brand.id || brand)
          }
        }

        this.request('POST', `/posts`, body, ({ data }) => {
          this.editDialog.display = false
          this.$refs.crudTable.requestData()
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Post is created'
          })

          this.editDialog.display = false
          this.$refs.crudTable.requestData()
        })
      }
    },
    deletePost (id) {
      this.request('DELETE', `/posts/${id}`, {}, ({ data }) => {
        this.$refs.crudTable.requestData()
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Post is deleted'
        })
      })
    },
    restorePost (id) {
      this.request('PATCH', `/trash/un-delete/post/${id}`, {}, ({ data }) => {
        this.$refs.crudTable.requestData({ deleted: 1 })
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Post is restored'
        })
      })
    },
    editPost (data) {
      this.editDialog.display = true
      this.editDialog.data = this.gFunc.deepCopy(data)
      this.editDialog.type = 'edit'

      this.editDialog.saveFunction = () => {
        let body = {
          data: {
            title: this.editDialog.data.title,
            type: this.editDialog.data.type,
            language: this.editDialog.data.language,
            weight: this.editDialog.data.weight,
            body: this.editDialog.data.body,
            brands: this.editDialog.data.brands.map(brand => brand.id || brand)
          }
        }

        this.request('PATCH', `/posts/${data.id}`, body, ({ data }) => {
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Post is updated'
          })

          this.editDialog.display = false
          this.$refs.crudTable.requestData()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
